// SECTIONS

import {
    constables,
    resize
} from './base.js';


// DEPENDENCIES

import {
    toggle
} from "slide-element";
import forceReflow from 'bianco.force-reflow';

// VARIABLES

const breakpoint = constables().breakpoint;

const times = constables().times;
const timem = constables().timem;
const timel = constables().timel;

let time,
    vw;


// INIT

export function initcontent() {

    window.addEventListener('resize', resizethis);
    resizethis();

    routes();

}

function resizethis() {

    vw = resize();

}

function routes() {

   uijahr();

}

function uijahr(){

    let liste = document.querySelectorAll('section.jahr-liste');

    liste.forEach(jahr => {
    
        let titel = jahr.querySelector('h2.jahr');

        titel.addEventListener('click', () => {

            togglejahr(jahr);

        })

    })

}

function togglejahr(jahr){

    let monate = jahr.querySelector('ul.monat-liste');

    jahr.classList.toggle('oeffnen')

    toggle(monate, {
        duration: times,
        easing: "cubic-bezier(0.76, 0.33, 0.12, 1)",
    }).then((isOpen) => {

        jahr.classList.toggle('zugeklappt');

    });

}