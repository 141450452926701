// SECTIONS

import {
    constables,
    reseturl,
    newurl,
    buildurl
} from './base.js';


// DEPENDENCIES

import 'tiny-slider/dist/tiny-slider.css';
import {
    tns
} from "tiny-slider";

import {
    toggle
} from "slide-element";

import {
    scrollTo
} from 'scroll-js';

import forceReflow from 'bianco.force-reflow';

// VARIABLES

const breakpoint = constables().breakpoint;

const times = constables().times;
const timem = constables().timem;
const timel = constables().timel;


// INIT

export function initveranstaltung() {

    let landing = document.querySelector('article.veranstaltung-landing');
    if (landing !== null) {

        veranstaltung(landing);

    }

    routes();

}

export function discreetly(slug) {

    let article = document.querySelector('.veranstaltung[data-slug="' + slug + '"]');
    let year = article.closest('section.jahr-liste');
    let landing

    year.classList.remove('zugeklappt');
    year.classList.add('oeffnen');

    forceReflow(article);

    load(slug, article);

    let vh = window.innerHeight;

    let tb = article.getBoundingClientRect();
    let t = tb.top - tb.height;

    let sh = window.pageYOffset || document.documentElement.scrollTop;

    scrollTo(document.body, {
        top: t + sh,
        easing: 'ease-in-out',
        duration: 800
    });

}

function routes() {

    let articles = document.querySelectorAll('section.veranstaltung');

    articles.forEach(parent => {

        let slug = parent.dataset.slug;
        let title = parent.querySelector('ul.veranstaltung-meta');

        title.addEventListener('click', () => {

            if (parent.classList.contains('zugeklappt')) {

                // closeall();
                load(slug, parent);

            } else {

                close(parent);

            }

            parent.classList.toggle('zugeklappt');

        })

    })

}

function load(slug, parent) {

    const fetcharchive = async () => {
        let url = buildurl("archiv.json?slug=" + slug);
        console.log(url);
        try {
            const response = await fetch(url);
            const {
                html,
                slug,
                title
            } = await response.json();

            serve(html, slug, parent);

            newurl(slug, title, 'archiv');


        } catch (error) {
            console.log('Fetch error: ', error);
        }
    }

    fetcharchive();

}

function serve(html, slug, parent) {

    let article = parent.querySelector('article');
    article.innerHTML = '';
    article.insertAdjacentHTML('beforeend', html);

    veranstaltung(article);

    toggle(article, {
        duration: timem,
        easing: "cubic-bezier(0.76, 0.33, 0.12, 1)",
        overflow: "hidden"
    }).then((isOpen) => {

        article.classList.remove('zugeklappt');

    });

}

function closeall() {

    let open = document.querySelectorAll('section.veranstaltung:not(.zugeklappt)');

    open.forEach(v => {

        close(v);

    })

}

function close(parent) {

    let article = parent.querySelector('article');

    toggle(article, {
        duration: times,
        easing: "cubic-bezier(0.76, 0.33, 0.12, 1)",
    }).then((isOpen) => {

        reseturl();

        setTimeout(function() {

            article.classList.add('zugeklappt');
            cleararticle(parent, article);

        }, times)

    });

}

function cleararticle(parent, article) {

    if (parent.classList.contains('zugeklappt')) {

        article.innerHTML = '';

    }

}

function veranstaltung(el) {

    let imgs = el.querySelector('.veranstaltung-bilder');

    if (imgs !== null) {

        images(imgs);

    }

}

function images(el) {

    var slider = tns({
        container: el,
        items: 1,
        slideBy: 'page',
        autoplay: false,
        mouseDrag: true,
        gutter: 10,
        navPosition: 'bottom',
        controls: false,
        autoHeight: false
    });

}