// SECTIONS

import {initnavigation} from './js/navigation';
import {initcontent} from './js/content';
import {initveranstaltung} from './js/veranstaltung';
import {initsearch} from './js/search';
import {initboot} from './js/boot';

// INIT

init();

function init(){

    initnavigation();
    initcontent();
    initveranstaltung();
    initsearch();
    
    initboot();

}