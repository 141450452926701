// SECTIONS

import {
    constables,
    reseturl,
    resize,
    buildurl,
    newurl
} from './base.js';


// DEPENDENCIES

import {
    scrollTo,
    scrollIntoView
} from 'scroll-js';
import {
    toggle
} from "slide-element";
import forceReflow from 'bianco.force-reflow';

// VARIABLES

const breakpoint = constables().breakpoint;

const times = constables().times;
const timem = constables().timem;
const timel = constables().timel;

let time,
    vw;


// INIT

export function initnavigation() {

    window.addEventListener('resize', resizethis);
    resizethis();

    routes();

}

function capitalize(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);

}

function resizethis() {

    vw = resize();

}

function routes() {

    uititle();
    uiinfo();
    uiinfokategorie();

}

function uititle() {

    let title = document.getElementById('menu-archiv');
    let front = title.querySelector('.front');

    if (front) {

        front.addEventListener('click', buildblinds);

    }

    let publik = title.querySelector('.publikationen');

    if (publik) {

        publik.addEventListener('click', uipublikationen);

    }

}

function buildblinds(ev, triggerclose, triggeropen) {

    let title = document.getElementById('menu-archiv'),
        blinds = title.querySelector('.blinds'),
        nav = title.querySelector('.blinds-nav');

    let open = document.body.hasAttribute('blinds');

    document.removeEventListener('click', blindsidle);

    if (!open && !triggerclose) {

        clearTimeout(time);
        infomobil(ev, true, false);
        closeinfokategorie();
        reseturl();

        let nh = nav.offsetHeight;

        blinds.style.height = nh + "px";

        document.body.setAttribute('blinds', '');
        document.addEventListener('click', blindsidle);

        time = setTimeout(function() {

            blinds.removeAttribute('style');
            title.classList.add('scroll');

        }, timem)

    } else if (open && !triggeropen) {

        clearTimeout(time);
        reseturl();

        let bh = blinds.offsetHeight;
        blinds.style.height = bh + "px";

        document.body.removeAttribute('blinds');

        forceReflow(blinds);
        blinds.style.height = "0px";

        title.classList.remove('scroll');

        time = setTimeout(function() {

            let liste = document.getElementById('publikationen-klappe');
            liste.style.display = 'none';

        }, timem)

    }


}

function blindsidle(ev) {

    let blinds = document.getElementById('menu-archiv');

    if (!blinds.contains(ev.srcElement)) {
        buildblinds(ev, true, false);
    }

}

function uipublikationen(ev) {

    let title = ev.target;
    let liste = document.getElementById('publikationen-klappe');

    toggle(liste, {
        duration: times,
        easing: "cubic-bezier(0.76, 0.33, 0.12, 1)",
    }).then((isOpen) => {


    });

    scrollIntoView(ev.target, document.getElementById('menu-archiv'), { behavior: 'smooth', duration: times });


}

// KATEGORIEN

function uiinfo() {

    // MOBILE

    let menu = document.getElementById('menu-info');

    if (menu) {

        menu.addEventListener('click', infomobil);

        // MENU

        let list = menu.querySelectorAll('.info-kategorie');

        list.forEach((item) => {

            let slug = item.dataset.menu;
            item.addEventListener('click', () => {
                openinfokategorie(slug, item)
            }, false)

        });

    }

    // MENU CLOSE

    let menuclose = document.getElementById('info-return');

    if (menuclose) {

        menuclose.addEventListener('click', closeinfokategorie);

    }

    // MENU RETURN

    let closelist = document.querySelectorAll('.blinds-info-return');

    closelist.forEach(el => {

        el.addEventListener('click', closeinfokategorie);

    })

}

function openinfokategorie(slug, item) {

    closeinfokategorie(slug);

    document.body.setAttribute('menu-' + slug, '');
    forceReflow(item);
    document.body.setAttribute('menu-' + slug + '-offen', '');

    newurl(slug,capitalize(slug));

}

function closeinfokategorie(ex) {

    let menu = document.getElementById('menu-info');
    let list = menu.querySelectorAll('.info-kategorie');

    reseturl();

    list.forEach((item) => {

        let slug = item.dataset.menu;
        document.body.removeAttribute('menu-' + slug + '-offen');

        if (ex !== slug) {

            setTimeout(function() {

                document.body.removeAttribute('menu-' + slug);


            }, times);

        }


    });

    document.body.removeAttribute('info-offen');

    closeinfo();

}

function infomobil(ev, triggerclose, triggeropen) {

    if (vw <= breakpoint) {

        let menu = document.getElementById('menu-info');

        let open = document.body.hasAttribute('info-offen');

        if (!open && !triggerclose) {

            buildblinds(ev, true, false);

            let nav = menu.querySelector('.info-kategorie-text');

            document.body.setAttribute('info-offen', '');

        } else if (open && !triggeropen) {

            let srcel = ev.srcElement;

            if (!srcel.classList.contains('info-kategorie-text')) {

                document.body.removeAttribute('info-offen');

            }

        }

    }

}

function uiinfokategorie() {

    let liste = document.querySelectorAll('.info-liste');

    liste.forEach((item) => {

        item.addEventListener('click', buildinfokategorie);

    })

}

function buildinfokategorie(ev) {

    let el = ev.srcElement;
    let slug = el.dataset.slug;
    let kategorie = ev.srcElement.dataset.kategorie;
    let blinds = document.querySelector('.info-blinds[menu="' + kategorie + '"');

    let url = buildurl(kategorie + ".json?slug=" + slug);

    if (typeof slug != 'undefined' || slug != null) {

        let klappe = el.querySelector('.info-inhalt');

        if (!el.classList.contains('offen')) {

            fetch(url)
                .then(function(response) {
                    return response.json();
                })
                .then(function(page) {

                    klappe.innerHTML = page[0].inhalt;
                    openinfo(el, klappe, blinds);

                    el.classList.add('offen');

                })

        } else {

            el.classList.remove('offen');

        }

    }


}

function openinfo(el, klappe, blinds) {

    let skip = el.classList.contains('offen');

    closeinfo();

    if (!skip) {

        let index = Array.prototype.findIndex.call(el.parentNode.children, (c) => c === el);

        // if (index > closeel.index) {

        //     let kh = closeel.el.getBoundingClientRect().top - blinds.getBoundingClientRect().top + blinds.scrollTop + 1 + el.offsetHeight;

        //     scrollTo(blinds, {
        //         top: kh,
        //         easing: 'cubic-bezier(0.63, 0.14, 0.41, 0.92)',
        //         duration: times * 1.1
        //     }).then(function() {

        //     });

        // } else {

        //     let kh = el.getBoundingClientRect().top - blinds.getBoundingClientRect().top + blinds.scrollTop + 1;
        //     scrollTo(blinds, {
        //         top: kh,
        //         easing: 'ease-in',
        //         duration: timem
        //     }).then(function() {

        //     });

        // }

        toggle(klappe, {
            duration: timem,
            easing: "ease-in",
        })

        klappe.classList.add('info-kategorie-offen');

    }

}

function closeinfo() {

    let close = document.querySelectorAll('.info-kategorie-offen');
    let closeel = {};

    if (close.length) {

        close.forEach((item) => {

            closeel.index = Array.prototype.findIndex.call(item.parentNode.parentNode.children, (c) => c === item.parentNode);
            closeel.el = item.parentNode;

            toggle(item, {
                duration: timem,
                easing: "cubic-bezier(0.76, 0.33, 0.12, 1)",
            }).then((isOpen) => {

                item.innerHTML = '';
                item.classList.remove('info-kategorie-offen');
                item.parentElement.classList.remove('offen');

            });

        })

    }


}