// SECTIONS

import {
    constables,
    reseturl
} from './base.js';

import {
    discreetly
} from './veranstaltung';


// DEPENDENCIES

import {
    parsePath,
    roundCommands,
    roundCorners
} from 'svg-round-corners';

import LazyLoad from "vanilla-lazyload";

import inView from 'in-view';

// VARIABLES

const breakpoint = constables().breakpoint;

const times = constables().times;
const timem = constables().timem;
const timel = constables().timel;
const timexl = constables().timexl;

let time,
    vw,
    vh;

let fps = 60;
let interval = 1000 / fps;
let then;

let c = true;

const svg = document.getElementById('svg-landing');
const el = document.getElementById('landing-path');
const clip = document.getElementById('landing-clip-path');
const gallery = document.getElementById('landing-gallery');
const imgs = gallery.querySelectorAll('img');
const landing = document.getElementById('landing');
const captions = document.querySelectorAll('.landing-caption');

let frames = 0;
let li = 0;
let next = (li + 1) < (imgs.length - 1) ? li + 1 : 0;

let lazyload = new LazyLoad({
    container: gallery
});

const dimensions = {
    scale: {
        size: 0.8,
        active: 0.95,
        min: 80,
        max: 100
    },
    padding: {
        size: 0.1,
        active: 0.0,
        min: 0,
        max: 30
    }
}

const points = [{
        part: "leftbar",
        point: [25, 26, 27, 28],
        route: 2,
        baseline: [24, 29]
    },
    {
        part: "rightbar",
        point: [6, 5, 4, 3],
        route: 1,
        baseline: false
    },
    {
        part: "midrightbar",
        point: [10, 9, 36, 35],
        route: 2,
        baseline: [11, 34]
    },
    {
        part: "midleftbar",
        point: [17, 18, 31, 32],
        route: 3,
        baseline: [16, 19, 30, 33]
    },
    {
        part: "rightbelt",
        point: [13, 14],
        route: 4,
        baseline: [16, 19, 30, 33],
        height: 77.64
    },
    {
        part: "leftbelt",
        point: [21, 22],
        route: 4,
        baseline: [29, 30, 30, 33],
        height: 77.64
    },

];

const active = {
    mid: 60,
    size: 60,
    state: 0
}

const states = [{
        mid: 55,
        size: 75
    },
    {
        mid: 54,
        size: 50
    },
    {
        mid: 38,
        size: 25
    },
    {
        mid: 61.5,
        size: 35
    },
    {
        mid: 60,
        size: 60
    },
    {
        mid: 50,
        size: 25
    },
    {
        mid: 59,
        size: 82
    },
    {
        mid: 30,
        size: 9
    }

]

let base = [{
        marker: "M",
        values: {
            x: 100,
            y: 0
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 100,
            y: 14.06
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 87.3,
            y: 14.06
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 87.3,
            y: 41.06
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 100,
            y: 41.06
        },
        lb: false,
        rb: true
    },
    {
        marker: "L",
        values: {
            x: 100,
            y: 64.97,
        },
        lb: false,
        rb: true
    },
    {
        marker: "L",
        values: {
            x: 87.3,
            y: 64.97
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 87.3,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 77.22,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 77.22,
            y: 64.98
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 71.57,
            y: 64.98
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 75.3,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 65.22,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 63.3,
            y: 77.64
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 53.02,
            y: 77.64
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 51.11,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 41.12,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 44.85,
            y: 64.97
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 35.07,
            y: 64.97
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 38.81,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 28.73,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 26.81,
            y: 77.64
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 16.53,
            y: 77.64
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 14.62,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 4.64,
            y: 100
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 8.37,
            y: 64.97
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 0,
            y: 64.97
        },
        lb: true,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 0,
            y: 41.07
        },
        lb: true,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 10.89,
            y: 41.07
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 15.22,
            y: 0
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 28.22,
            y: 0
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 32.55,
            y: 41.07
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 47.37,
            y: 41.07
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 51.71,
            y: 0
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 64.71,
            y: 0
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 69.04,
            y: 41.07
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 77.21,
            y: 41.07
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 77.21,
            y: 0
        },
        lb: false,
        rb: false
    },
    {
        marker: "L",
        values: {
            x: 100,
            y: 0
        },
        lb: false,
        rb: false
    },
    {
        marker: "Z",
        values: {
            x: 100,
            y: 0
        },
        lb: false,
        rb: false
    }
]

let plot = structuredClone(base);

// INIT

export function initsvg() {

    routes();

}

function routes() {

    viewport();
    window.addEventListener('resize', viewport);

    listenviewport();

    captions.forEach((caption) => {

        caption.addEventListener('click', senddiscreetly);

    })

    preanimate();


    setTimeout(() => {

        if (window.scrollY < vh) {

            animate();

        } else {

            pauseanimation();

        }

    }, 1)


}

function listenviewport() {

    inView('#landing')
        .on('enter', returnanimation)
        .on('exit', pauseanimation);

}

function senddiscreetly() {

    discreetly(landing.dataset.slug);

}


function preanimate() {

    path();
    loadimg();

    resetpoints();

}

function getsize(element) {
    let s = window.getComputedStyle(element);
    let px = parseFloat(s.paddingLeft) +
        parseFloat(s.paddingRight);
    let py = parseFloat(s.paddingTop) +
        parseFloat(s.paddingBottom);

    return {
        width: element.clientWidth - px,
        height: element.clientHeight - py,
        padding: py
    }
}

function viewport() {

    let b = getsize(svg);
    vw = b.width;
    vh = b.height;

    inView.offset(b.padding);

}

function loadimg() {

    if (imgs[next].classList.contains('pre-lazy')) {

        imgs[next].classList.add('lazy');
        imgs[next].classList.remove('pre-lazy');
        lazyload.update();

    }

}

function updatecaptions() {

    let figcaptions = gallery.querySelectorAll('figcaption');
    let caption = figcaptions[next].innerHTML;
    captions[next % 2].innerHTML = caption;

}

function updateslug() {

    let figcaptions = gallery.querySelectorAll('figcaption');
    let s = figcaptions[next].dataset.slug;
    landing.dataset.slug = s;

}

function crossfade(easing) {

    imgs[li].style.opacity = (1 - easing)
    imgs[next].style.opacity = easing;

    captions[li % 2].style.opacity = 1 - easing;
    captions[next % 2].style.opacity = easing;

}


function pauseanimation() {

    if (c) {

        c = false;
        pauseanimation();

        document.body.removeAttribute('landing');
        document.body.setAttribute('landing-idle', '');
        landing.style.height = "100%";

    }

}

function returnanimation() {

    if (!c) {

        document.body.setAttribute('landing', '');
        document.body.removeAttribute('landing-idle');
        c = true;

        preanimate();
        animate();

    }

}

function random(min, max) {
    return Math.random() * (max - min) + min;
}

function loop() {

    if (frames >= timexl) {

        let easing = easingf(frames / timexl);

        updatebase(base, easing);
        plot = structuredClone(base);

        frames = 0;
        li = (li + 1) < (imgs.length - 1) ? li + 1 : 0;
        next = (li + 1) < (imgs.length - 1) ? li + 1 : 0;

        dimensions.scale.active = dimensions.scale.size;
        dimensions.scale.size = random(dimensions.scale.min, dimensions.scale.max) / 100;

        dimensions.padding.active = dimensions.padding.size;
        dimensions.padding.size = random(dimensions.padding.min, dimensions.padding.max) / 100;

        resetpoints();

        loadimg();
        updatecaptions();

        imgs[li].style.opacity = 1;
        imgs[next].style.opacity = 0;

    } else if (frames === Math.floor(timexl / 2)) {

        updateslug();

    }

}

function resetpoints() {

    active.state = Math.floor(random(0, states.length));

    active.size = states[active.state].size;
    active.mid = states[active.state].mid;

}

function easingf(x) {
    return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
}

function animate(timestamp) {

    frames++;

    if (c) {

        requestAnimationFrame(animate);

        if (then === undefined) {
            then = timestamp;
        }

        const delta = timestamp - then;

        if (delta > interval) {

            then = timestamp - (delta % interval);

            path();
            loop();

        }


    }

}


function path() {

    let easing = easingf(frames / timexl);

    updatebase(plot, easing);
    let path = buildpath(easing);

    let r = roundCorners(path, 12, false);

    el.setAttribute('d', r.path);
    clip.setAttribute('d', r.path);

    landing.style.height = "calc(100% - " + window.scrollY + "px)";

}

function movecaptions(s) {

    let p = (vw - (s * vw)) / 2;

    captions.forEach(caption => {

        caption.style.transform = 'rotate(180deg) translateX(calc(-' + p + 'px + 1rem))';

    })

};

function updatebase(cmds, easing) {

    points.forEach(p => {

        updatepoint(p, cmds, easing);

    })

    crossfade(easing);

}

function updatepoint(p, cmds, easing) {

    if (p.route === 1) {

        cmds[p.point[0]].values.y = base[p.point[0]].values.y + (active.mid + active.size / 2 - base[p.point[0]].values.y) * easing;
        cmds[p.point[1]].values.y = base[p.point[1]].values.y + (active.mid + active.size / 2 - base[p.point[1]].values.y) * easing;

        cmds[p.point[2]].values.y = base[p.point[2]].values.y + (active.mid - active.size / 2 - base[p.point[2]].values.y) * easing;
        cmds[p.point[3]].values.y = base[p.point[3]].values.y + (active.mid - active.size / 2 - base[p.point[3]].values.y) * easing;

    } else if (p.route === 2) {

        cmds[p.point[0]].values.y = base[p.point[0]].values.y + (active.mid + active.size / 2 - base[p.point[0]].values.y) * easing;
        cmds[p.point[1]].values.y = base[p.point[1]].values.y + (active.mid + active.size / 2 - base[p.point[1]].values.y) * easing;

        cmds[p.point[2]].values.y = base[p.point[2]].values.y + (active.mid - active.size / 2 - base[p.point[2]].values.y) * easing;
        cmds[p.point[3]].values.y = base[p.point[3]].values.y + (active.mid - active.size / 2 - base[p.point[3]].values.y) * easing;

        cmds[p.point[0]].values.x = (cmds[p.baseline[1]].values.x - cmds[p.baseline[0]].values.x) * ((100 - cmds[p.point[0]].values.y) / 100) + cmds[p.baseline[0]].values.x;
        cmds[p.point[3]].values.x = (cmds[p.baseline[1]].values.x - cmds[p.baseline[0]].values.x) * ((100 - cmds[p.point[3]].values.y) / 100) + cmds[p.baseline[0]].values.x;

    } else if (p.route === 3) {

        cmds[p.point[0]].values.y = base[p.point[0]].values.y + (active.mid + active.size / 2 - base[p.point[0]].values.y) * easing;
        cmds[p.point[1]].values.y = base[p.point[1]].values.y + (active.mid + active.size / 2 - base[p.point[1]].values.y) * easing;

        cmds[p.point[2]].values.y = base[p.point[2]].values.y + (active.mid - active.size / 2 - base[p.point[2]].values.y) * easing;
        cmds[p.point[3]].values.y = base[p.point[3]].values.y + (active.mid - active.size / 2 - base[p.point[3]].values.y) * easing;

        cmds[p.point[0]].values.x = (cmds[p.baseline[3]].values.x - cmds[p.baseline[0]].values.x) * ((100 - cmds[p.point[0]].values.y) / 100) + cmds[p.baseline[0]].values.x;
        cmds[p.point[3]].values.x = (cmds[p.baseline[3]].values.x - cmds[p.baseline[0]].values.x) * ((100 - cmds[p.point[3]].values.y) / 100) + cmds[p.baseline[0]].values.x;

        cmds[p.point[1]].values.x = (cmds[p.baseline[2]].values.x - cmds[p.baseline[1]].values.x) * ((100 - cmds[p.point[1]].values.y) / 100) + cmds[p.baseline[1]].values.x;
        cmds[p.point[2]].values.x = (cmds[p.baseline[2]].values.x - cmds[p.baseline[1]].values.x) * ((100 - cmds[p.point[2]].values.y) / 100) + cmds[p.baseline[1]].values.x;

    } else if (p.route === 4) {


        if (active.mid + active.size / 2 >= p.height) {

            cmds[p.point[0]].values.y = base[p.point[0]].values.y + (active.mid + active.size / 2 - base[p.point[0]].values.y) * easing;
            cmds[p.point[1]].values.y = base[p.point[1]].values.y + (active.mid + active.size / 2 - base[p.point[1]].values.y) * easing;

        } else {

            cmds[p.point[0]].values.y = base[p.point[0]].values.y + (p.height - base[p.point[0]].values.y) * easing;
            cmds[p.point[1]].values.y = base[p.point[1]].values.y + (p.height - base[p.point[1]].values.y) * easing;

        }

    }

}

function buildpath(easing) {

    let s = (dimensions.scale.active + (dimensions.scale.size - dimensions.scale.active) * easing);
    let padding = dimensions.padding.active + (dimensions.padding.size - dimensions.padding.active) * easing;
    let mid = (1 - s + padding) * vw / 2;
    let pmid = (1 - s) * vw / 2;

    movecaptions(s);

    let xper = vw * (s - padding);

    let path = 'M';

    plot.forEach(p => {

        let x = p.values.x / 100 * xper + mid;
        let y = (vh - window.scrollY) > 0 ? p.values.y / 100 * (vh - window.scrollY) : 0;

        if (p.rb || p.lb) {

            x = p.values.x / 100 * s * vw + pmid;

        }

        path += x + ',' + y + ',';

    })

    path += 'Z';

    return path;

}