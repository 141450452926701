const baseurl = '/';

export function constables() {

    return {
        breakpoint: 880,
        times: 500,
        timem: 800,
        timel: 2000,
        timexl: 350
    };

}

export function resize() {

    let vw = window.innerWidth;
    return vw;

}

export function buildurl(route){

    reseturl();
    let url = window.location.origin + baseurl + route;
    return url;
    
}

export function newurl(slug, title, route) {

    let basetitle = "Arbeit am Feminismus";

    if (typeof title != 'undefined' || title != null) {

    basetitle += " | " + title;

    }

    let newstate = window.location.origin + baseurl

    if (typeof route != 'undefined' || route != null) {

        newstate += route + "/" + slug;

    } else {

        newstate += slug;

    }

    const stateObj = {
        title: basetitle
    };
    history.replaceState(stateObj, '', newstate);

}

export function reseturl() {

    let newstate = window.location.origin + baseurl + 'archiv';
    const stateObj = {
        title: 'Arbeit am Feminismus'
    };
    history.replaceState(stateObj, '', newstate);

}