import {
    initsvg
} from './svg';

import {
    constables,
    reseturl,
    newurl,
    buildurl
} from './base.js';

// DEPENDENCIES

import 'tiny-slider/dist/tiny-slider.css';
import {
    tns
} from "tiny-slider";

// VARIABLES

const times = constables().times;
const timem = constables().timem;
const timel = constables().timel;
const timexl = constables().timexl;

// INIT

export function initboot() {

    initlanding();

    initarticle();

}

function initlanding(){

    if (document.body.hasAttribute('landing')){

        initsvg();

    }

}

function initarticle() {

    let article = document.querySelector('section.veranstaltung-landing');
    if (article !== null) {

        document.fonts.ready
            .then(() => {
                moveTo(article)
            })
            .catch(() => {
                console.log("Error");
            });

    }

}

function moveTo(article) {

    let menu = document.getElementById('menu-archiv').getBoundingClientRect();
    let mt = menu.top + menu.height;

    let t = article.getBoundingClientRect().top;

    let sh = window.pageYOffset || document.documentElement.scrollTop;

    window.scroll({
        top: t - mt + sh
    });

}