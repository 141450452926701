// SECTIONS

import {
    buildurl,
    constables,
    reseturl
} from './base.js';

import {
    scrollIntoView
} from 'scroll-js';


// DEPENDENCIES

import {
    toggle,
    down
} from "slide-element";

import forceReflow from 'bianco.force-reflow';

// VARIABLES

const times = constables().times;
const timem = constables().timem;
const timel = constables().timel;


// INIT

export function initsearch() {

    routes();

}

function routes() {

    uifilter();

}

function uifilter() {

    let menu = document.getElementById('menu-archiv');
    let front = menu.querySelector('div.front');

    let filter = menu.querySelectorAll('li.filter');
    filter.forEach(f => {

        f.addEventListener('click', () => {

            removefilter();
            f.classList.add('active');
            queryfilter();

            front.addEventListener('click', clearfilter);

        })

    })


}

function clearfilter() {

    let menu = document.getElementById('menu-archiv');
    let front = menu.querySelector('div.front');
    front.removeEventListener('click', clearfilter);

    removefilter();

    setTimeout(() => {

        let info = document.getElementById('suche-info');
        let infotxt = document.getElementById('suche-beschreibung');
        infotxt.innerHTML = '';
        info.classList.remove('aktiv');

        let parent = document.getElementById('suche');
        parent.innerHTML = '';

    }, timem);

}

function removefilter() {

    let menu = document.getElementById('menu-archiv');
    let query = menu.querySelectorAll('li.filter.active');

    query.forEach(f => {

        f.classList.remove('active');

    })

}

function queryfilter() {

    let filter = [];

    let menu = document.getElementById('menu-archiv');
    let query = menu.querySelector('li.filter.active');

    let tag = query.dataset.filter;

    const fetcharchive = async () => {
        let url = buildurl("suche.json?tag=" + tag);
        try {
            const response = await fetch(url);
            const {
                filter
            } = await response.json();

            serve(filter, query);


        } catch (error) {
            console.log('Fetch error: ', error);
        }
    }

    fetcharchive();

}

function serve(filter, el) {

    let info = document.getElementById('suche-info');
    let infotxt = document.getElementById('suche-beschreibung');
    infotxt.innerHTML = '';
    info.classList.remove('aktiv');

    if ((typeof el.dataset.beschreibung != 'undefined' || el.dataset.beschreibung != null) && el.hasAttribute('data-beschreibung')) {

        infotxt.innerHTML = el.dataset.beschreibung;
        info.classList.add('aktiv');

    }

    let parent = document.getElementById('suche');
    parent.innerHTML = '';

    filter.forEach(f => {

        let e = document.createElement('section');
        e.innerHTML = f.html;
        parent.appendChild(e);

    })

    let blinds = document.getElementById('suche-blinds');

    if (!blinds.classList.contains('aktiv')) {

        scrollIntoView(document.getElementById('suche-scroll'), document.getElementById('menu-archiv'), {
            behavior: 'smooth',
            duration: times
        });

        toggle(blinds, {
            duration: timem,
            easing: "cubic-bezier(0.76, 0.33, 0.12, 1)",
            overflow: "hidden"
        }).then((isOpen) => {

            blinds.classList.add('aktiv');

        });



    } else {

        down(blinds, {
            duration: timem,
            easing: "cubic-bezier(0.76, 0.33, 0.12, 1)",
            overflow: "hidden"
        }).then((isOpen) => {


        });
    }

}